import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/bank-integral/:orderNo', // 兴业6积分
    name: 'BankIntegral',
    meta: { title: '券码' },
    component: () => import('@/views/BankIntegral.vue')
  },
  {
    path: '/bank-coupon-package/:redeemNo', // 兴业6积分卡包
    name: 'BankCouponPackage',
    meta: { title: '券码' },
    component: () => import('@/views/BankCouponPackage.vue')
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    meta: { title: '系统升级' },
    component: () => import('@/views/upgrade.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
